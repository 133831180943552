import { FC } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import { persistStore } from 'redux-persist';

import 'react-loading-skeleton/dist/skeleton.css';

import AppQueryClientProvider from 'src/context/AppQueryClientProvider';
import Loader from 'src/ui/Loader';
import { createStore } from 'src/store/store';
import persistCrosstab from './store/utils/persistCrosstab';
import { authPersistConfig } from './store/auth';
import { AppThemeProvider } from './theme';
import AppContent from './AppContent';

const store = createStore();
const persistor = persistStore(store);
persistCrosstab(store, authPersistConfig);

const App: FC = () => {
  return (
    <ReduxProvider store={store}>
      <AppQueryClientProvider>
        <AppThemeProvider>
          <PersistGate
            persistor={persistor}
            loading={<Loader sx={{ position: 'absolute', height: '100%', width: '100%' }} />}
          >
            <AppContent />
          </PersistGate>
        </AppThemeProvider>
      </AppQueryClientProvider>
    </ReduxProvider>
  );
};

export default App;
