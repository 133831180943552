import { FieldError } from 'react-hook-form';
import { FC } from 'react';
import { ProductMetadata } from '@mottmac-moata/identity-sdk';
import { MenuItem, TextField } from '@mui/material';
import { type ControllerRenderProps } from 'react-hook-form';
import { SerializedError } from '@reduxjs/toolkit';
import Loader from 'src/ui/Loader';

interface ProductDropdownProps {
  defaultProductId?: number;
  productIdError?: FieldError;
  field?: ControllerRenderProps<{ productId?: number }, 'productId'>;
  onProductsChange?: (isLoading: boolean) => void;
  products?: Pick<ProductMetadata, 'productId' | 'name'>[];
  isLoading?: boolean;
  error?: SerializedError;
  disabled?: boolean;
}

const ProductDropdown: FC<ProductDropdownProps> = ({
  defaultProductId,
  productIdError,
  field,
  products,
  isLoading,
  error,
  disabled,
}) => {
  const productErrorMessage = (productIdError?.message ?? error) ? 'Failed to load products' : undefined;

  const isPreselectedProductFound = products?.find((product) => product.productId === defaultProductId);
  const value = defaultProductId ? isPreselectedProductFound && field?.value : products && field?.value;

  return (
    <TextField
      {...field}
      required
      disabled={disabled}
      select
      label="Product"
      value={value || ''}
      error={!!productIdError || !!error}
      helperText={productErrorMessage}
      InputProps={{
        endAdornment: isLoading ? <Loader size="1rem" /> : undefined,
      }}
    >
      {products?.map((product) => (
        <MenuItem key={product.productId} value={product.productId}>
          {product.name}
        </MenuItem>
      )) ?? <span />}
    </TextField>
  );
};

export default ProductDropdown;
