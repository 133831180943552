import { FC } from 'react';
import { Tabs } from '@mui/material';
import { GTMEvents, trackGTMEvent } from 'src/services/telemetry/gtm';
import pages from '../headerLinksList';
import RouterNavLink from './RouterNavLink';
import { useScrollToActiveItem } from './useScrollToActiveItem';

const StdHeaderLinks: FC = () => {
  const { tabsRef } = useScrollToActiveItem();

  return (
    <Tabs ref={tabsRef} value={false} variant="scrollable" scrollButtons="auto" role="navigation" aria-label="Main">
      {pages.map((page) => (
        <RouterNavLink
          key={page.linkName}
          label={page.linkName}
          to={page.linkUrl}
          data-gtm-header-link={page.linkName}
          onClick={() => {
            trackGTMEvent(GTMEvents.header.navItemClick({ target: page.navItemTarget }));
          }}
        />
      ))}
    </Tabs>
  );
};

export default StdHeaderLinks;
